#input-receipt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 1.25rem;

    #submit-button {
        color: #020202;
        height: 4rem;
        // width: 100%;
        background-color: transparent;
        border: none;
        font-size: 2rem;
        transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background-color */

        &:hover {
            transform: scale(1.1); /* Make the button bigger */
            background-color: rgba(255, 255, 255, 0.2); /* Change transparency */
        }
    }
    
}

.container{
    margin: 0rem 0rem;
    padding: 0rem 0rem;
    display: flex;
    align-self: center;
}