// FIRST SCREEN COMPONENTS
#input-receipt-container {
    .upload-section {
        display: flex;
        flex-direction: column;
        .upload-instruction {
            align-self: flex-start;
            margin-bottom: .2rem;
        }
    
        .input-container {
            margin-bottom: 2rem;
        }
    
        .participants-instruction {
            margin-bottom: .2rem !important;
            text-align: start;
        }
    
        .participants-input {
            border-radius: 8px;
            border-color: rgb(118, 118, 118);
            padding: .1rem .5rem;
            outline: none;
            margin-bottom: 1rem;
        }
        #input-names-btn{
            
            background-color: transparent;
            border: none;
            color: #020202;
            &:hover {
                transform: scale(1.1); /* Make the button bigger */
                background-color: rgba(255, 255, 255, 0.2); /* Change transparency */
            }
        }
    }

    #tags-container {
        display: flex;
        flex-wrap: wrap;
        // width: 100%;
        .tag {
            margin-right: .8rem;
            margin-bottom: .5rem;
            padding: 0.15rem 0.35rem .25rem .35rem;
            border: 2px solid #cccfd3;
            border-radius: 5px;
            background-color: #E7E9EC;
            cursor: pointer;

            .bolden {
                font-weight: bolder;
            }
        }
    }

    #submit-button {
        min-width: 6rem;
        align-self: flex-start;
    }
}

.modal-content {
    .modal-body {
        
        #summary-row {
            margin-bottom: .75rem;
            
            .summary-top-row {
                display: flex;
                justify-content: space-between;

            }
            #claimed-summary-container {
                .item-row {
                    display: flex;
                    justify-content: space-between;
                }
            }
            #tip-row,
            #tax-row {
                display: flex;
                justify-content: space-between;
            }
        }

        #final-row {
            display: flex;
            justify-content: space-between;
        }


    }
}
#edit-body-container,
#add-item-body-container,
#add-user-body-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: center;

    #edit-form{
        width: 85%;
    }
    
    #submit-edit-button,
    #submit-add-new-item-button,
    #submit-add-user-button {
        height: 2.75rem;
        border-radius: 8px 8px 8px 8px;
        display: flex;
        align-items: center;
        margin: 1rem 1rem;
        padding: 15px 25px;
        color: #020202;
        
        // width: 100%;
        background-color: #dddddd;
        border: none;
        font-size: 1rem;
        transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background-color */

        &:hover {
            transform: scale(1.1); /* Make the button bigger */
            //background-color: rgba(255, 255, 255, 0.2); /* Change transparency */
        }
    }

}

#share-body-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: center;

    input {
        height: 2.75rem;
        border-radius: 8px 0 0 8px;
    }
    
    #copy-button {
        height: 2.75rem;
        border-radius: 0 8px 8px 0;
        display: flex;
        align-items: center;
        padding: 15px 25px;
        color: #020202;
        
        // width: 100%;
        background-color: #dddddd;
        border: none;
        font-size: 1rem;
        transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background-color */

        &:hover {
            transform: scale(1.1); /* Make the button bigger */
            //background-color: rgba(255, 255, 255, 0.2); /* Change transparency */
        }
    }

}


// SECOND SCREEN COMPONENTS
#nav-container {
    display: flex;
    justify-content: center;
    height: 3rem;
    #share-icon{
        color: #dddddd;
    }
    #share-button,
    #home-button{
        // align-items: center;
        // display: flex;
        // justify-content: center;
        // margin: 0.5rem 0;
        // max-width: 15rem;
        // width: 80%;
        color: #dddddd;
        //height: 2rem;
        // width: 100%;
        //background-color: rgba(255, 255, 255, 0.4);
        border: none;
        font-size: 1rem;
        transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background-color */
    
        &:hover {
            transform: scale(1.1); /* Make the button bigger */
            color: rgba(255, 255, 255, 0.2);
            //background-color: rgba(255, 255, 255, 0.2); /* Change transparency */
        }
    }
    .container {
        justify-content: center;
        padding: 1rem 1rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        #remaining-text {
            margin: .75rem 1rem;
        }
        // #share-icon{
        //     color:#020202;
        //     font-size: 1.5rem;
        // }
       
    }

}

#bottom-row-container{
    display: flex;
    justify-content: center;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    bottom: 0rem;
    padding-bottom: 1rem;
    margin-bottom: 0rem;
    margin-top: 0rem;
    width: 100%;
    background-color: #ffffff; /* Optional: Set a background color */
    // padding: 1rem; /* Optional: Add some padding */
    // box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
    z-index: 1000;
}

#receipt-breakdown-container {
    display: flex;
    flex-direction: column;
    padding: 2 2;
    margin: 0rem 0rem;
    width: 100%;
    
    .summary-row {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height:5rem;
        margin: 0rem 0rem;
        margin-top: 0rem;
        margin-bottom: 0rem;
        padding: .25rem 0rem;
        position: -webkit-sticky; /* For Safari */
        position: sticky;
        top: 3rem;
        z-index: 1000;
        
        background-color: #ffffff;
        .row-container {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-evenly;
        }
        #summary-button,
        #add-item-button,
        #add-user-button {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 0.5rem 0;
            max-width: 15rem;
            width: 80%;
            color: #000000;
            height: 2rem;
            // width: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            border: none;
            font-size: 1rem;
            transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background-color */
        
            &:hover {
                transform: scale(1.1); /* Make the button bigger */
                background-color: rgba(0, 0, 0, 0.4); /* Change transparency */
            }
        }
        
        #name-dropdown {
            align-items: center;
            display: flex;
            justify-content: center;
            width: 80%;
            margin: 0.5rem 0;
            max-width: 15rem;
            
            color: #000000;
            height: 2rem;
            // width: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            border: none;
            font-size: 1rem;
            transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background-color */
        
            &:hover {
                transform: scale(1.1); /* Make the button bigger */
                background-color: rgba(0, 0, 0, 0.4); /* Change transparency */
            }
        }
        #name-dropdown-highlight{
            @extend #name-dropdown;
            background-color: #cbe9f4;
            outline: 1px solid #cccfd3;
            transition: .15s;
        }
        #total-text,
        #remaining-text,
        #select-for-user-text,
        #user-total-text{
            font-size: 1rem;
        }
        
        @media (max-width: 576px) {
            #name-dropdown {
                width: 85%; /* Adjust the width as needed */
                font-size: 0.8rem; /* Adjust the font size as needed */
                height: 2rem;
            }
            #summary-button,
            #add-item-button,
            #add-user-button {
                width: 85%; /* Adjust the width as needed */
                font-size: 0.8rem; /* Adjust the font size as needed */
                margin: 0.5rem 0;
                max-width: 10rem;
                height: 2rem;
            }
            #total-text,
            #remaining-text,
            #select-for-user-text,
            #user-total-text{
                font-size: 0.6rem;
            }

        }
        
        
    }
    .bottom-row {
        @extend .summary-row;
        display: flex;
        justify-content: center;
        position: -webkit-sticky; /* For Safari */
        position: sticky;
        bottom: 0rem;
        margin-bottom: 0rem;
        margin-top: 0rem;
        width: 100%;
        background-color: #ffffff; /* Optional: Set a background color */
        // padding: 1rem; /* Optional: Add some padding */
        // box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
        z-index: 1000;
        .bottom-row-col{
            display: flex;
            
            justify-content: center;
            align-items: center;
            padding: 0rem 0rem;
            .bottom-row-col-row{
                width: 100%;
                justify-content: center;
                .name-dropdown-container{
                    padding: 0rem 0rem;
                    justify-content: center;
                    display: flex;
                }
            }
        }
        .bottom-button {
            width: 80%;
            //margin: .5rem 1rem;
            color: #000000;
            height: 2rem;
            // width: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            border: none;
            font-size: 1rem;
            transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background-color */
        
            &:hover {
                transform: scale(1.1); /* Make the button bigger */
                background-color: rgba(255, 255, 255, 0.2); /* Change transparency */
            }
        }
        @media (max-width: 576px) {
            
            .bottom-button {
                width: 85%; /* Adjust the width as needed */
                font-size: 0.8rem; /* Adjust the font size as needed */
                max-width: 10rem;
                height: 2rem;
            }

        }
    }
    #receipt-grid-row{
        margin-bottom: 2rem;
        // overflow-y: hidden;
        // overscroll-behavior: contain;
        //min-height: 102vh;
        //align-content: center;
    }
    #receipt-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        #reciept-list{
            width: 100%;
        }
    
        .item-name {
            font-family: "Inter",sans-serif;
            color: #020202;
        }
        .item-price{
            font-family: "Inter",sans-serif;
            color: #020202;
        }
        #tap-instruction{
            font-family: "Inter",sans-serif;
            color: #363636;
        }
        span {
            font-family: "Inter",sans-serif;
            color: #020202;
        }
    
        // Styling for the name buttons
        .name-buttons-container {
            margin-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            .toggle-button {
                padding: .15rem .45rem .35rem .45rem;
                margin-right: .8rem;
                margin-bottom: .5rem;
                border: 2px solid #cccfd3;
                border-radius: 5px;
                background-color: #E7E9EC;
                cursor: pointer;
                font-family: "Inter",sans-serif;
                color: #020202;
            }
    
            .selected {
                background-color: #a7a9ac;
                outline: 2px solid #cccfd3;
                transition: .15s;
            }
        }
    
        // Styling for the receipt items
        .receipt-item {
            width: 100%;
            margin: .25rem 0rem;
            min-height: 4rem;
            padding: 0 .25rem;
            border: 1px solid ;
            border-radius: 0px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add shadow

            background-color: #ffffff;
            cursor: pointer;
    
            #receipt-item-card-body{
                padding: 0rem 0rem;
            }
            .recipt-item-col-start{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: .5rem 0rem;
            }
            .recipt-item-col-end{
                display: flex;
                padding: .5rem 0rem;
                align-items: center;
            }
            .item-top-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .item-text-main{
                    font-size: 1rem;
                }
            }
            
    
            .item-bottom-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .item-text-instrucation{
                    font-size: 0.8rem;
                }
    
                #claimed-by {
                    i {
                        font-size: 0.7rem;
                    }
                }
            }
    
            .item-grow {
                font-weight: 600;
            }
            @media (max-width: 576px) {
                .item-top-row{
                    .item-text-main{
                        font-size: 0.8rem;
                    }
                }
                .item-bottom-row{
                    .item-text-instrucation{
                        font-size: 0.7rem;
                    }
                    #claimed-by {
                        i {
                            font-size: 0.6rem;
                        }
                    }
                }
            }
            #more-options-dropdown{
                display: flex;
                justify-content: right;
                align-items: center;
                width: 100%;
            }
            #more-options-dropdown-toggle {
                display:flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                color:#020202;
                max-width: 4rem;
                height: 2rem;
                padding: .5rem .5rem;
                // width: 100%;
                background-color: transparent;
                border: none;
                font-size: 1rem;
                transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for transform and background-color */
            
                &:hover {
                    transform: scale(1.1); /* Make the button bigger */
                    background-color: rgba(0, 0, 0, 0.2); /* Change transparency */
                }   
            }
            .more-options-text{
                color:#020202;
            }
            @media (max-width: 576px) {
                .more-options-dropdown-toggle {
                    width: 100%; /* Adjust the width as needed */
                    font-size: 0.8rem; /* Adjust the font size as needed */
                    max-width: 3rem;
                    height: 1.5rem;
                }
            }
           
        }

        .receipt-item.selected {
            background-color: #cbe9f4;
            outline: 2px solid #cccfd3;
            transition: .15s;
        }

        .receipt-item.highlight {
            background-color: #cbe9f4;
            outline: 1px solid #cccfd3;
            transition: .15s;
        }


         .floating-button-row{
            .floating-button {
                position: fixed;
                bottom: 6rem;
                right: 9rem;
                border-radius: 50%;
                width: 3rem;
                height: 3rem;
                border-color:transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                z-index: 1000; /* Ensure it stays on top */
                background-color: rgba(0, 0, 0, 0.6);
                color:#020202;
                &:hover {
                    transform: scale(1.2); /* Make the button bigger */
                    background-color: rgba(0, 0, 0, 0.2); /* Change transparency */
                }
            }
            @media (max-width: 576px) {
                
                .floating-button {
                    bottom: 4rem;
                    right: 5rem;
                    width: 3rem;
                    height: 3rem;
                    font-size: 0.8rem; /* Adjust the font size as needed */

                }
                
    
            }
        }
        
    }


}

#input-names-input{
    width:50%;
}

