.receipt-item {
    .top-row{
        .item-name {
            color: #020202; // Change this to your desired color
        }
    
        .item-price {
            color: #363636; // Change this to your desired color
        }
    }


}

// .summary-row{
//     .row-container{
//         #summary-button {
        
//         }
//     }
// }





