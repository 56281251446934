#root{
    display: flex;
    flex-direction: column;
    height: 100%;
}
body {
    height:100%;
    background-color: #ffffff; /* For browsers that do not support gradients */
    
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p, h1, label {
    font-family: "Inter",sans-serif;
    color: #020202;
    // text-align: center;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import './App.scss';
@import './Shared/ReceiptBreakdown/ReceiptBreakdown.scss';
@import './Components/InputComponents.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import './Shared/SharedComponents.scss';
@import './Shared/InputImage/InputImage.scss';
@import './Components/MainComponent.scss';
@import './Shared/ReceiptItem/ReceiptItem.scss';
